/* eslint-disable padded-blocks */

import { lazy } from "react";
import { retry } from "../utils/index";

export const routeBlockchain = [
  // Dashboard
  {
    path: "/blockchain/dashboard",
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "Dashboard" */ "../pages/dashboard")
      )
    )
  },

  // Profile
  {
    path: "/blockchain/profile",
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "Profile" */ "../pages/profile"))
    )
  },

  // Help
  {
    path: "/blockchain/help",
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "Help" */ "../pages/help"))
    )
  },

  // History
  {
    path: "/blockchain/history",
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "History" */ "../pages/history"))
    )
  },

  // Activity
  {
    path: "/blockchain/activity",
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "Activity" */ "../pages/activity")
      )
    )
  },
  {
    path: "/blockchain/activity/create",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Create Activity" */ "../pages/activity/create"
        )
      )
    )
  },
  {
    path: "/blockchain/activity/detail/:id",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Detail Activity" */ "../pages/activity/detail"
        )
      )
    )
  },
  {
    path: "/blockchain/activity/verify/:id",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Verify Activity" */ "../pages/activity/verify"
        )
      )
    )
  },
  {
    path: "/blockchain/activity/accept/:id",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Accept Activity" */ "../pages/activity/accept"
        )
      )
    )
  },

  // Contract
  {
    path: "/blockchain/contract",
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "Contract" */ "../pages/contract")
      )
    )
  },
  {
    path: "/blockchain/contract/detail/:id",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Detail Contract" */ "../pages/contract/detail"
        )
      )
    )
  },

  // Account
  {
    path: "/blockchain/account",
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "Account" */ "../pages/account"))
    )
  },
  {
    path: "/blockchain/account-user",
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "AccountUser" */ "../pages/account/user")
      )
    )
  },
  {
    path: "/blockchain/account-user/:uuid",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AccountUserDetail" */ "../pages/account/user/detail"
        )
      )
    )
  },
  {
    path: "/blockchain/account-role",
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "AccountRole" */ "../pages/account/role")
      )
    )
  },
  {
    path: "/blockchain/account-permission",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AccountPermission" */ "../pages/account/permission"
        )
      )
    )
  }
];

export const routeTraceability = [
  // Dashboard
  {
    path: "/traceability/dashboard",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Dashboard" */ "../pages/dashboard/traceability"
        )
      )
    )
  },

  // Traceability
  {
    path: "/traceability/traceability",
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "Traceability" */ "../pages/traceability")
      )
    )
  },
  {
    path: "/traceability/traceability/add",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Add Traceability" */ "../pages/traceability/add"
        )
      )
    )
  },
  {
    path: "/traceability/traceability/:id",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Detail Traceability" */ "../pages/traceability/add"
        )
      )
    )
  },
  {
    path: "/traceability/traceability/detail/:id",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Detail Traceability" */ "../pages/traceability/detail"
        )
      )
    )
  },

  // History
  {
    path: "/traceability/history",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "History" */ "../pages/history/traceability"
        )
      )
    )
  },

  // Account
  {
    path: "/traceability/account",
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "Account" */ "../pages/traceabilityAccount"))
    )
  },
];

export const routeWithoutAuth = [
  {
    path: "/login",
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "Login" */ "../pages/login"))
    )
  },
  {
    path: "/forgot-password",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Forgot Password" */ "../pages/forgotPassword"
        )
      )
    )
  },
  {
    path: "/forgot-password/:token",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Forgot Password Verify" */ "../pages/forgotPassword/verify"
        )
      )
    )
  },
  {
    path: "/guest",
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "Guest" */ "../pages/guest"))
    )
  },
  {
    path: "/guest/verify",
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "Guest Verify" */ "../pages/guest/verify")
      )
    )
  },
  {
    path: "/user-creation-verify/:token",
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "User Creation Verify" */ "../pages/userCreationVerify"
        )
      )
    )
  }
];
