import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  getTraceable,
  addTraceable,
  getTraceableById,
  deleteTraceable,
  downloadTemplateTraceable,
  uploadTraceable,
  getProgressUploadTraceable,
  getTraceability,
  addTraceability,
  getTraceabilityByCode,
  getTraceabilityById,
  deleteTraceability,
  getCertification,
  downloadTemplateTraceability,
  uploadTraceability
} from "../../../services/traceability";
import {
  SET_STATE,
  GET_TRACEABLE,
  ADD_TRACEABLE,
  GET_TRACEABLE_BY_ID,
  DETELE_TRACEABLE,
  DOWNLOAD_TEMPLATE_TRACEABLE,
  UPLOAD_TRACEABLE,
  GET_PROGRESS_UPLOAD_TRACEABLE,
  GET_TRACEABILITY,
  ADD_TRACEABILITY,
  GET_TRACEABILITY_BY_CODE,
  GET_TRACEABILITY_BY_ID,
  DETELE_TRACEABILITY,
  DOWNLOAD_TEMPLATE_TRACEABILITY,
  UPLOAD_TRACEABILITY,
  GET_CERTIFICATION
} from "./actions";
import {
  setObjectLocalStorage,
  handleDownload,
  getObjectLocalStorage
} from "../../../utils";
import { ENDPOINT } from "../../../utils/constants";

// Traceable
export function* GET_TRACEABLE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getTraceable, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_TRACEABLE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(addTraceable, action.data);
    if (action.data.is_draft) {
      setObjectLocalStorage("message", {
        message: "You have saved new production code as draft",
        type: "draft"
      });
    } else {
      setObjectLocalStorage("message", {
        message: "You have submitted new Product Information",
        type: "success"
      });
    }

    yield put({
      type: SET_STATE,
      payload: { isAdd: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TRACEABLE_BY_ID_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getTraceableById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_TRACEABLE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(deleteTraceable, action.id);
    yield put({
      type: SET_STATE,
      payload: { isDelete: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_TEMPLATE_TRACEABLE_F() {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateTraceable);
    yield handleDownload(data, "template_production_code.xlsx");
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPLOAD_TRACEABLE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(uploadTraceable, action.data);
    yield put({ type: SET_STATE, payload: { isUpload: true } });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PROGRESS_UPLOAD_TRACEABLE_F() {
  yield put({
    type: SET_STATE,
    payload: { loading: true, dataProgressUpload: null }
  });
  try {
    const { data } = yield call(getProgressUploadTraceable);

    const auth = getObjectLocalStorage("auth");
    var domain = window.location.hostname.split(".").slice(-2).join(".");
    document.cookie = `Authorization = Bearer ${auth.access_token}; domain=${domain}; path=/; secure`;
    document.cookie = `Authorization = Bearer ${auth.access_token}; domain=${domain}; path=/`;
    window.open(ENDPOINT.API_TRACEABLE + "/admin", "_blank");

    yield put({ type: SET_STATE, payload: { dataProgressUpload: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

// Traceability
export function* GET_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getTraceability, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(addTraceability, action.data);
    if (action.data.is_draft) {
      setObjectLocalStorage("message", {
        message: "You have saved new traceability code as draft",
        type: "draft"
      });
    } else {
      setObjectLocalStorage("message", {
        message: "You have submitted new Product Information",
        type: "success"
      });
    }

    yield put({
      type: SET_STATE,
      payload: { isAdd: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TRACEABILITY_BY_CODE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getTraceabilityByCode, action.code);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TRACEABILITY_BY_ID_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getTraceabilityById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(deleteTraceability, action.id);
    yield put({
      type: SET_STATE,
      payload: { isDelete: true, message: data.message }
    });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_TEMPLATE_TRACEABILITY_F() {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateTraceability);
    yield handleDownload(data, "template_traceability_code.xlsx");
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPLOAD_TRACEABILITY_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(uploadTraceability, action.data);
    yield put({ type: SET_STATE, payload: { isUpload: true } });
  } catch (error) {
    yield put({ type: SET_STATE, payload: { error: error.response.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

// Certification
export function* GET_CERTIFICATION_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getCertification, action.param);
    yield put({ type: SET_STATE, payload: { dataCertification: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_TRACEABLE, GET_TRACEABLE_F),
  takeLatest(ADD_TRACEABLE, ADD_TRACEABLE_F),
  takeLatest(GET_TRACEABLE_BY_ID, GET_TRACEABLE_BY_ID_F),
  takeLatest(DETELE_TRACEABLE, DELETE_TRACEABLE_F),
  takeLatest(DOWNLOAD_TEMPLATE_TRACEABLE, DOWNLOAD_TEMPLATE_TRACEABLE_F),
  takeLatest(UPLOAD_TRACEABLE, UPLOAD_TRACEABLE_F),
  takeLatest(GET_PROGRESS_UPLOAD_TRACEABLE, GET_PROGRESS_UPLOAD_TRACEABLE_F),

  takeLatest(GET_TRACEABILITY, GET_TRACEABILITY_F),
  takeLatest(ADD_TRACEABILITY, ADD_TRACEABILITY_F),
  takeLatest(GET_TRACEABILITY_BY_CODE, GET_TRACEABILITY_BY_CODE_F),
  takeLatest(GET_TRACEABILITY_BY_ID, GET_TRACEABILITY_BY_ID_F),
  takeLatest(DETELE_TRACEABILITY, DELETE_TRACEABILITY_F),
  takeLatest(DOWNLOAD_TEMPLATE_TRACEABILITY, DOWNLOAD_TEMPLATE_TRACEABILITY_F),
  takeLatest(UPLOAD_TRACEABILITY, UPLOAD_TRACEABILITY_F),

  takeLatest(GET_CERTIFICATION, GET_CERTIFICATION_F)
]);
