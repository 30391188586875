import { EVENT_NAME } from "./constants";

const setObjectLocalStorage = (name = "", data) => {
  if (typeof data === "object") {
    localStorage.setItem(name, JSON.stringify(data));
  }
};

const getObjectLocalStorage = (name) => {
  const data = JSON.parse(localStorage.getItem(name) || "{}");
  if (Object.keys(data)?.length === 0) {
    return null;
  }

  return data;
};

const removeLocalStorage = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("user");
  window.location = "/login";
};

const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const getEventColor = (data) => {
  const codeFirstEvent = data?.split("-")[2];
  const codeLastEvent = data?.split("-")[3];
  const codeEvent = `${codeFirstEvent}${
    codeLastEvent ? `-${codeLastEvent}` : ""
  }`;

  let color = "";
  switch (codeEvent) {
    case "1-1":
    case "2-1":
    case "4-1":
    case "5-1":
    case "6-1":
    case "7-1":
    case "8-1":
    case "9-1":
      color = "Orange";
      break;
    case "1-2":
    case "2-3":
    case "4-2":
    case "5-2":
    case "6-2":
    case "7-2":
    case "8-2":
    case "9-2":
    case "3":
      color = "Green";
      break;
    case "2-2":
    case "offchain":
      color = "Blue";
      break;
    default:
      break;
  }

  return color;
};

const getunixTimestamp = () => {
  return Math.floor(new Date().getTime() / 1000);
};

const monthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const monthNameSimple = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Des"
];

const convertDate = (item, isMonthLong = false) => {
  if (item == null || item === "") {
    return "";
  }

  const newDate = new Date(item * 1000);
  const date = newDate.getDate();

  let monthsx = newDate.getMonth();
  monthsx += 1;

  let month = monthNameSimple[monthsx - 1];
  if (isMonthLong) {
    month = monthName[monthsx - 1];
  }

  const year = newDate.getFullYear();

  let h = newDate.getHours().toString();
  let m = newDate.getMinutes().toString();
  let s = newDate.getSeconds().toString();

  if (h.length < 2) {
    h = `0${h}`;
  }

  if (m.length < 2) {
    m = `0${m}`;
  }

  if (s.length < 2) {
    s = `0${s}`;
  }

  const dTime = h + ":" + m;

  return {
    date,
    month,
    year,
    dTime,
    full: `${date} ${month} ${year}`,
    fullTime: `${date} ${month} ${year} ${dTime}`,
    ddmmyyyy: `${String(newDate.getDate()).padStart(2, "0")}-${String(
      monthsx
    ).padStart(2, "0")}-${year}`,
    yyyymmdd: `${year}-${String(monthsx).padStart(2, "0")}-${String(
      newDate.getDate()
    ).padStart(2, "0")}`,
    ddmmyyyyTimeSlash: `${String(newDate.getDate()).padStart(2, "0")}/${String(
      monthsx
    ).padStart(2, "0")}/${year} ${dTime}`
  };
};

const capitalizeFirstLetter = (string) => {
  if (string == null || string === "") {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const calculateDateDifference = (date1) => {
  const date2 = Math.floor(Date.now() / 1000);

  // get total seconds between the times
  var seconds = Math.abs(date1 - date2);

  // calculate days between the times
  var days = Math.floor(seconds / 86400);
  seconds -= days * 86400;

  // calculate hours between the times
  var hours = Math.floor(seconds / 3600) % 24;
  seconds -= hours * 3600;

  // calculate minutes between the times
  var minutes = Math.floor(seconds / 60) % 60;
  seconds -= minutes * 60;

  if (days > 0) {
    return `${days} ${days > 1 ? "days" : "day"} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours > 1 ? "hours" : "hour"} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? "minutes" : "minute"} ago`;
  } else {
    return "-";
  }
};

const convertUTCtoLocalTime = (date) => {
  var inputDate = new Date(date);
  var newDate = new Date(
    inputDate.getTime() + inputDate.getTimezoneOffset() * 60 * 1000
  );

  var dateFormated =
    [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate()].join(
      "-"
    ) +
    " " +
    [newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()].join(":");

  return dateFormated;
};

const convertPercentage = (data) => {
  const value = data?.split(".")[0];
  return `${value}%`;
};

const calculateInventoryAge = (date1, date2) => {
  let dateDifference = null;
  const date1Parts = date1?.split("-");
  const date2Parts = date2?.split("-");

  if (date1Parts?.length === 3 && date2Parts?.length === 3) {
    const year1 = parseInt(date1Parts[0], 10) + 2000;
    const month1 = new Date(
      Date.parse(`${date1Parts[1]} 1, ${year1}`)
    ).getMonth();
    const day1 = parseInt(date1Parts[2], 10);

    const year2 = parseInt(date2Parts[0], 10) + 2000;
    const month2 = new Date(
      Date.parse(`${date2Parts[1]} 1, ${year2}`)
    ).getMonth();
    const day2 = parseInt(date2Parts[2], 10);

    const date1Obj = new Date(year1, month1, day1);
    const date2Obj = new Date(year2, month2, day2);

    const timeDifference = Math.abs(date2Obj - date1Obj);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    dateDifference = daysDifference;
  }

  return dateDifference;
};

const convertDateToTime = (x, range = false, isHours = false) => {
  if (range) {
    const start = new Date(x + " " + "00" + ":" + "00" + ":" + "00");
    const end = new Date(x + " " + "23" + ":" + "59" + ":" + "59");
    return { start: start.getTime(), end: end.getTime() };
  } else {
    const date = new Date();
    let newDate = new Date(
      x +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
    );
    if (isHours) {
      newDate = new Date(x);
    }
    return newDate.getTime();
  }
};

const convertTimeToDate = (item, isMonthLong = false) => {
  if (item == null || item === "") {
    return "";
  }
  const newDate = new Date(item);
  const date = newDate.getDate();
  let monthsx = newDate.getMonth();
  monthsx += 1;
  const year = newDate.getFullYear();
  let month = monthNameSimple[monthsx - 1];
  if (isMonthLong) {
    month = monthName[monthsx - 1];
  }
  let h = newDate.getHours().toString();
  let m = newDate.getMinutes().toString();
  let s = newDate.getSeconds().toString();
  if (h.length < 2) {
    h = `0${h}`;
  }
  if (m.length < 2) {
    m = `0${m}`;
  }
  if (s.length < 2) {
    s = `0${s}`;
  }
  const dTime = h + ":" + m;
  return {
    date,
    month,
    year,
    dTime,
    full: `${date} ${month} ${year}`,
    fullTime: `${date} ${month} ${year} ${dTime}`,
    ddmmyyyy: `${String(newDate.getDate()).padStart(2, "0")}-${String(
      monthsx
    ).padStart(2, "0")}-${year}`,
    yyyymmdd: `${year}-${String(monthsx).padStart(2, "0")}-${String(
      newDate.getDate()
    ).padStart(2, "0")}`,
    ddmmyyyyTimeSlash: `${String(newDate.getDate()).padStart(2, "0")}/${String(
      monthsx
    ).padStart(2, "0")}/${year} ${dTime}`
  };
};

const getNextEventName = (currentEvent) => {
  const events = Object.values(EVENT_NAME);

  if (currentEvent === "Completed") return currentEvent;

  let nextEvent = "";
  for (let index = 0; index < events.length; index++) {
    const element = events[index];
    if (element === currentEvent) {
      nextEvent = events[index + 1];
      break;
    }
  }
  return nextEvent;
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const handleDownload = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export {
  setObjectLocalStorage,
  getObjectLocalStorage,
  removeLocalStorage,
  retry,
  getEventColor,
  getunixTimestamp,
  convertDate,
  capitalizeFirstLetter,
  calculateDateDifference,
  convertUTCtoLocalTime,
  convertPercentage,
  calculateInventoryAge,
  convertDateToTime,
  convertTimeToDate,
  getNextEventName,
  toBase64,
  handleDownload
};
